import React from 'react';
import { Logo } from './logo';
import './App.scss';

export const Home = () => {
  return (
    <div className="App">
      <header className="App-header">
        <Logo className="App-logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a className="App-link" href="/how-to-install">
          How to install
        </a>
      </header>
    </div>
  );
};
