import React from 'react';
import './App.scss';

export const HowToInstall = () => {
  return (
    <div className="App">
      <header className="App-header">
        <p>How-to-install</p>
        <a className="App-link" href="/">
          Back
        </a>
      </header>
    </div>
  );
};
